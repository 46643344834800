import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Signup.css";
import "./assets/css/Login.css";
import "./assets/css/ForgetPassword.css";
import "./assets/css/CreatePassword.css";
import "./assets/css/DashboardLayout.css";
import "./assets/css/DashboardDesign.css";
import "./assets/css/Chat.css";
import "./assets/css/AdminPanel.css";
import React, { useState, useEffect } from "react";
import AuthRoutes from "./routes/AuthRoutes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from "./pages/Dashboard/DashboardLayout/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardMain from "./routes/DashboardMain";
import Alert from "./pages/Alert";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllCountry,
  GetAlldEPARTMENT,
  GetAllDrivers,
} from "./network/Network";
import {
  AllCountry,
  AllDepartments,
  AllDrivers,
} from "./redux/actions/AuthAction";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [currentPage, setCurrentPage] = useState();
  // const isLogin = false;

  // == get Country api ==
  useEffect(() => {
    GetAllCountry()
      .then((res) => {
        console.log(res);
        dispatch(AllCountry(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);

  useEffect(() => {
    GetAlldEPARTMENT(currentPage, Token)
      .then((res) => {
        console.log(res);
        dispatch(AllDepartments(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);

  //  == Get All Driver Api ==
  useEffect(() => {
    GetAllDrivers(currentPage, Token)
      .then((res) => {
        console.log(res);
        dispatch(AllDrivers(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, [currentPage]);

  return (
    <>
      {isLogin ? (
        <>
          <DashboardLayout />
          <ToastContainer />
        </>
      ) : (
        <>
          <AuthRoutes />
          <ToastContainer />
        </>
      )}
      <Alert />
    </>
  );
}

export default App;
