import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  ALL_DEPARTMENT,
  ALL_COUNTRY,
  ALL_DRIVERS,
  // GET_ME_SUCCESS,
  // SET_PROFILE_IMG,
  // LOGOUT_REQUEST,
  // LOGOUT_SUCCESS,
  // LOGOUT_ERROR,
  LOGOUT,
  USER_TOKEN,
  JOBS,
  EDIT_PROFILE,
  All_PAYMENT_METHOD,
  SERVICES,
  ADDRESS,
  All_JOB_DATA,
  ALL_STATES_DATA,
  NOTIFICATION,
  CHAT_SEND,
  PROVIDERSERVICEDATA,
  USER_MESSAGES,
  CHAT_USERDATA,
} from "../Types";

const initialState = {
  isLogin: false,
  loading: false,
  // forgotLoading: false,
  // setPasswordLoading: false,
  users: {},
  token: "",
  department: [],
  country: [],
  drivers: [],
  allJobData: [],
  services: [],
  paymentMethod: {},
  addressData: [],
  statesData: {},
  notifications: {},
  outgoingMessage: {},
  UserMessages: {},
  ChatUserData: {},
  providerServiceData: {},
};
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        users: action.payload,
        loading: false,
        isLogin: true,
      });
    }
    case LOGIN_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        isLogin: false,
      });
    }
    case USER_TOKEN: {
      return Object.assign({}, state, {
        token: action.payload,
      });
    }
    case SIGNUP_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case SIGNUP_SUCCESS: {
      return Object.assign({}, state, {
        users: action.data,
        loading: false,
      });
    }
    case SIGNUP_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        isLogin: false,
      });
    }
    case FORGOT_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        forgotLoading: true,
        isSuccess: false,
      });
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        forgotLoading: false,
        isSuccess: true,
      });
    }
    case FORGOT_PASSWORD_ERROR: {
      return Object.assign({}, state, {
        forgotLoading: false,
        isSuccess: false,
      });
    }
    case SET_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        setPasswordLoading: true,
      });
    }
    case SET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        setPasswordLoading: false,
      });
    }
    case SET_PASSWORD_ERROR: {
      return Object.assign({}, state, {
        setPasswordLoading: false,
      });
    }
    case LOGOUT:
      return {
        users: null,
        isLogin: false,
      };

    case ALL_DEPARTMENT: {
      return Object.assign({}, state, {
        ...state,
        department: action.payload,
      });
    }

    case ALL_DRIVERS: {
      return Object.assign({}, state, {
        ...state,
        drivers: action.payload,
      });
    }

    case ALL_COUNTRY: {
      return Object.assign({}, state, {
        ...state,
        country: action.payload,
      });
    }

    case EDIT_PROFILE: {
      return Object.assign({}, state, {
        ...state,
        users: action.payload,
      });
    }
    case All_PAYMENT_METHOD: {
      return Object.assign({}, state, {
        ...state,
        paymentMethod: action.payload,
      });
    }
    case SERVICES: {
      return Object.assign({}, state, {
        ...state,
        services: action.payload,
      });
    }

    case ADDRESS: {
      return Object.assign({}, state, {
        ...state,
        addressData: action.payload,
      });
    }

    case All_JOB_DATA: {
      return Object.assign({}, state, {
        ...state,
        allJobData: action.payload,
      });
    }
    case ALL_STATES_DATA: {
      return Object.assign({}, state, {
        ...state,
        statesData: action.payload,
      });
    }

    //   case JOBS:
    //     return {
    //       ...state,
    //         jobs: action.payload,
    //   };
    //
    //
    //   case NOTIFICATION: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       notifications: action.payload,
    //     });
    //   }
    //   case CHAT_SEND: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       outgoingMessage: action.payload,
    //     });
    //   }
    //   case SERVICES: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       services: action.payload,
    //     });
    //   }
    //   case PROVIDERSERVICEDATA: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       providerServiceData: action.payload,
    //     });
    //   }
    //   case USER_MESSAGES: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       UserMessages: action.payload,
    //     });
    //   }
    //   case CHAT_USERDATA: {
    //     return Object.assign({}, state, {
    //       ...state,
    //       ChatUserData: action.payload,
    //     });
    //   }
    default:
      return state;
  }
};
export default AuthReducer;

// import { SIGNUP, LOGIN, LOGOUT,} from '../Types';

// const initialState = {
//     users: {},
//     isLogin: false,

// }
// const AuthReducer = (state = initialState, action) => {

//     switch(action.type) {
//         case SIGNUP:
//             return {
//               ...state,
//               users: action.payload,
//             };
//         case LOGIN:
//             return {
//               ...state,
//               users: action.payload,
//               isLogin:true,
//             };
//         case LOGOUT:
//             return {
//                 users: null,
//                 isLogin:false,
//              };

//         default:
//                 return state;
//     }
// };

// export default AuthReducer;
