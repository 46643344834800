import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function Chat() {
  const [sendMessage, setSendMessage] = useState("");

  return (
    <>
      <div className="chat-Container">
        <div className="serviceCard">
          <div className="chat-header">
            <div className="profile"></div>
            <div className="ml-3">
              <p className="user-name">Franklin Gothic</p>
              <p className="status">Online Now</p>
            </div>
          </div>
          <hr />
          <div className="All-message-container">
            <div className="incoming">
              <div className="chat-picture-incoming"></div>
              <div className="chat-message-incoming"></div>
            </div>
            <div className="outgoing">
              <div className="chat-picture-outgoing"></div>
              <div className="chat-message-outgoing"></div>
            </div>
            <div className="incoming">
              <div className="chat-picture-incoming"></div>
              <div className="chat-message-incoming"></div>
            </div>
            <div className="outgoing">
              <div className="chat-picture-outgoing"></div>
              <div className="chat-message-outgoing"></div>
            </div>
            <div className="outgoing">
              <div className="chat-picture-outgoing"></div>
              <div className="chat-message-outgoing"></div>
            </div>
            <div className="outgoing">
              <div className="chat-picture-outgoing"></div>
              <div className="chat-message-outgoing"></div>
            </div>
          </div>
        </div>
        <div className="input-Card">
          <input
            type="text"
            value={sendMessage}
            onChange={(e) => setSendMessage(e.target.value)}
            placeholder="Write Your Message"
          />
          <div className="send-container">
            <button
              type="button"
              className="btn send-btn"
              style={{ width: "unset" }}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="service-icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
