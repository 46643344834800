import React, { useState, useEffect } from "react";
import { SpinnerCircular } from "spinners-react";
import { GetAllInvoice } from "../../../network/Network";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

function Payments() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [spinLoad, setSpinLoad] = useState(false);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const [allPayment, setAllPayment] = useState([]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //  == Get Invoice ==
  useEffect(() => {
    setSpinLoad(true);
    GetAllInvoice(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllPayment(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
        // dispatch(NotificationApi(notification));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Payments </p>
        <div className="serviceCard">
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Driver</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {allPayment?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.customer?.first_name}</td>
                      <td>
                        {item?.driver ? (
                          <>
                            {item?.driver?.first_name} {""}{" "}
                            {item?.driver?.last_name}
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td>{item?.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Payments;
