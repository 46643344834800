import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  GetAlldEPARTMENT,
  GetAllReports,
  PostNewDepartment,
} from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DepartmentModal from "../../../components/DashboardComp/Modal/DepartmentModal";
import { AllDepartments } from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function ReportIssue() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [allReportData, setAllReportData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllReports(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllReportData(res?.data?.response?.data?.data);
        // dispatch(AllDepartments(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Report </p>
        <div className="serviceCard">
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Message</th>

                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {allReportData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>
                        {item?.user?.first_name}
                        {item?.user?.last_name}
                      </td>
                      <td>{item?.user?.role}</td>
                      <td>{item?.subject}</td>
                      <td>{item?.message}</td>

                      {/* <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                        />
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportIssue;
