import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetAllDrivers } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { AllDrivers } from "../../../redux/actions/AuthAction";
import ReactPaginate from "react-paginate";

function Drivers() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const Navigate = useNavigate();
  const [driverData, setDriverData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //  == Get All Driver Api ==
  useEffect(() => {
    setSpinLoad(true);
    GetAllDrivers(currentPage, Token)
      .then((res) => {
        console.log(res);
        setDriverData(res?.data?.response?.data?.data);
        dispatch(AllDrivers(res?.data?.response?.data?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Drivers </p>
        <div className="serviceCard">
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Email</th>
                  <th scope="col"> View Details</th>
                </tr>
              </thead>
              <tbody>
                {driverData.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.email}</td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "unset" }}
                            onClick={() =>
                              Navigate(
                                `/adminpanel/driverdetails/${item?.first_name}`,
                                {
                                  state: item,
                                }
                              )
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Drivers;
