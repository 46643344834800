import React from "react";
import { useLocation } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";

function EmploymentDetails() {
  const Location = useLocation();
  console.log(Location);
  const EmploymentData = Location?.state;
  return (
    <>
      <div className="driver-Container">
        <p className="dashboard-title">Employment Details </p>
        <div className="serviceCard">
          <p className="driver-details">{EmploymentData?.title}</p>
          <div className="row">
            <div className="col-md-12">
              <p className="driver-details">{EmploymentData?.description}</p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Department ID: {""}
                {EmploymentData?.department_id}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Country: {""}
                {EmploymentData?.country?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmploymentDetails;
