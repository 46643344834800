import React, { useState, useEffect } from "react";
import ServicesCard from "../../../components/DashboardComp/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ServicesModal from "../../../components/DashboardComp/Modal/ServicesModal";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  AllServices,
  EditServiceApi,
  GetAllCountry,
  GetAlldEPARTMENT,
  PostServices,
  RemoveService,
} from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { AllCountry, AllDepartments } from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";

function ServiceType(props) {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [notification, setNotification] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [editData, setEditData] = useState([]);

  //  == Get Notification ==
  // useEffect(() => {
  //   GetAllNotifications(token)
  //     .then((res) => {
  //       console.log(res);
  //       setNotification(res?.data?.data);
  //       dispatch(NotificationApi(notification));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [notification]);

  // Invoke when user click to request another page.

  // const [currentItems, setCurrentItems] = useState(null);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setAvatar(file);
      console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  //  == Post Service Api ==
  const AddNewService = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!name || !avatar || !price || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("name", name);
    data.append("avatar", avatar);
    data.append("price", price);
    data.append("description", description);

    PostServices(data, Token)
      .then((res) => {
        console.log(res);
        serviceData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setIsOpenModal(false);
        setName("");
        setAvatar("");
        setPrice("");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setName("");
        setAvatar("");
        setPrice("");
        setDescription("");
      });
  };

  // == get Country api ==
  useEffect(() => {
    GetAllCountry()
      .then((res) => {
        console.log(res);
        dispatch(AllCountry(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);

  // == get Service api ==
  useEffect(() => {
    setSpinLoad(true);
    AllServices(currentPage)
      .then((res) => {
        console.log(res);
        setServiceData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        console.log(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  // == Remove Service ==
  const DeleteServiceHandler = (id, index) => {
    setRemoveLoading(true);
    RemoveService(id, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        serviceData?.splice(index, 1);
        setServiceData(res?.data?.response?.data?.data);
        // setServiceData(res?.data?.response?.data);
        setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(false);
      });
  };

  //  == Edit Service Api ==
  const EditService = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if (!name || !avatar || !price || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("name", name);
    data.append("avatar", avatar);
    data.append("price", price);
    data.append("description", description);

    EditServiceApi(data, Token, id)
      .then((res) => {
        console.log(res);
        serviceData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setName("");
        setAvatar("");
        setPrice("");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setName("");
        setAvatar("");
        setPrice("");
        setDescription("");
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Services </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Service");
              setName("");
              setPrice("");
              setAvatar("");
              setDescription("");
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add services
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Description</th>
                  <th scope="col">Price</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {serviceData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.name}</td>
                      <td>{item?.description}</td>
                      <td>${item?.price}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Service");
                            setName(item?.name);
                            setPrice(item?.price);
                            setAvatar(item?.icon);
                            setDescription(item?.description);
                            setEditData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteServiceHandler(item?.id, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <ServicesModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        name={name}
        setName={setName}
        avatar={avatar}
        avatar={setAvatar}
        price={price}
        setPrice={setPrice}
        description={description}
        setDescription={setDescription}
        pageTitle={pageTitle}
        handleImageUpload={handleImageUpload}
        AddNewService={AddNewService}
        loading={loading}
        editData={editData}
        EditService={EditService}
      />
    </>
  );
}

export default ServiceType;
