import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ServiceCard(props) {
  const { item, index } = props;

  return (
    <>
      <div className="col-md-4">
        <div className="serviceCard" key={index}>
          <div className="serviceCard-img">
            <img src={item?.image} alt="" className="image-fluid" />
          </div>
          <p className="serviceTitle">{item?.title}</p>
          <div>
            <p className="serviceDescription">{item?.description}</p>
          </div>
          <div className="icon-container">
            <FontAwesomeIcon icon={faArrowRight} className="service-icon" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
