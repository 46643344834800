import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  GetAllEmployment,
  PostNewDepartment,
  PostNewEmployment,
  RemoveEmployment,
  updateEmploymentApi,
} from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DepartmentModal from "../../../components/DashboardComp/Modal/DepartmentModal";
import EmploymentModal from "../../../components/DashboardComp/Modal/EmploymentModal";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function Employment() {
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const DepartmentData = useSelector((state) => state.AuthReducer.department);
  const CountryData = useSelector((state) => state.AuthReducer.country);
  const [allEmploymentData, setAllEmploymentData] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [department_id, setDepartment_id] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;
  const [data, setData] = useState([]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllEmployment(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllEmploymentData(res?.data?.response?.data?.data);
        // dispatch(AllServices(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  //  == Post New Employment Api ==
  const AddNewEmployment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!department_id || !country_id || !title || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (description.length < 20) {
      toast.error("Description must be at least 20 characters.");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("department_id", department_id);
    data.append("location_id", country_id);
    data.append("title", title);
    data.append("description", description);
    PostNewEmployment(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        allEmploymentData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setIsOpenModal(false);
        setDepartment_id("");
        setCountry_id("");
        setTitle(" ");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setDepartment_id("");
        setCountry_id("");
        setTitle(" ");
        setDescription("");
      });
  };

  // == Remove Service ==
  const DeleteEmploymentHandler = (id, index) => {
    // setRemoveLoading(true);
    RemoveEmployment(id, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        allEmploymentData?.splice(index, 1);
        setAllEmploymentData(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };

  // == Update Employment ==
  const UpdateEmploymentHandler = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if (!department_id || !country_id || !title || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (description.length < 20) {
      toast.error("Description must be at least 20 characters.");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("department_id", department_id);
    data.append("location_id", country_id);
    data.append("title", title);
    data.append("description", description);
    updateEmploymentApi(data, Token, id)
      .then((res) => {
        console.log(res);
        allEmploymentData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setDepartment_id("");
        setCountry_id("");
        setTitle(" ");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setDepartment_id("");
        setCountry_id("");
        setTitle(" ");
        setDescription("");
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">EMPLOYMENT </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Employment");
              setData("");
              setDepartment_id("");
              setCountry_id("");
              setTitle("");
              setDescription("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Employment
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">title</th>
                  <th scope="col">Description</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {allEmploymentData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.title}</td>
                      <td>{item?.description}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Employment");
                            setData(item);
                            setDepartment_id(item?.department_id);
                            setCountry_id(item?.country?.id);
                            setTitle(item?.title);
                            setDescription(item?.description);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() =>
                            DeleteEmploymentHandler(item?.id, index)
                          }
                        />
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "unset" }}
                            onClick={() =>
                              Navigate(
                                `/adminpanel/employmentdetails/${item?.id}`,
                                {
                                  state: item,
                                }
                              )
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <EmploymentModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        pageTitle={pageTitle}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        country_id={country_id}
        setCountry_id={setCountry_id}
        department_id={department_id}
        setDepartment_id={setDepartment_id}
        AddNewEmployment={AddNewEmployment}
        loading={loading}
        DepartmentData={DepartmentData}
        CountryData={CountryData}
        UpdateEmploymentHandler={UpdateEmploymentHandler}
        data={data}
      />
    </>
  );
}

export default Employment;
