import React from "react";
import { useLocation } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { ImageUrl } from "../../../network/ApiUrl";

function DriverDetails() {
  const Location = useLocation();
  console.log(Location);
  const DriverData = Location?.state;
  return (
    <>
      <div className="driver-Container">
        <p className="dashboard-title">Driver Details </p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              <img
                src={`${ImageUrl}/${DriverData?.profile_picture}`}
                className="image-fluid image-width"
                alt=""
              />
            </div>
          </div>
          <p className="driver-details">
            {DriverData?.first_name} {DriverData?.last_name}
          </p>
          <div className="row">
            <div className="col-md-6">
              <p className="driver-details">
                {" "}
                Email: {""}
                {DriverData?.email}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                {" "}
                Phone: {""}
                {DriverData?.phone_no}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                {" "}
                Address: {""}
                {DriverData?.address}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Vehicle Brand: {""}
                {DriverData?.vehicle_brand}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Vehicle Model: {""}
                {DriverData?.vehicle_model}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Vehicle Number{""}
                {DriverData?.vehicle_number}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DriverDetails;
