import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  GetAlldEPARTMENT,
  PostNewDepartment,
  RemoveDepart,
  UpdateDepartment,
} from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DepartmentModal from "../../../components/DashboardComp/Modal/DepartmentModal";
import { AllDepartments } from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function Department() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [allDepartmentData, setAllDepartmentData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const [data, setData] = useState([]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAlldEPARTMENT(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllDepartmentData(res?.data?.response?.data);
        dispatch(AllDepartments(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  //  == Post New Department Api ==
  const AddNewDepartment = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("title", title);
    PostNewDepartment(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        allDepartmentData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setIsOpenModal(false);
        setTitle(" ");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle(" ");
      });
  };

  // == Remove Department ==
  const DeleteDepartHandler = (id, index) => {
    // setRemoveLoading(true);
    RemoveDepart(id, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        allDepartmentData?.splice(index, 1);
        setAllDepartmentData(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };

  //  == Update New Department Api ==
  const UpdateDepartmentHandler = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("title", title);
    UpdateDepartment(data, Token, id)
      .then((res) => {
        console.log(res);
        allDepartmentData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setTitle(" ");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle(" ");
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Department </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Department");
              setTitle("");
              setData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Department
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {allDepartmentData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.title}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Department");
                            setTitle(item?.title);
                            setData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteDepartHandler(item?.id, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <DepartmentModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        pageTitle={pageTitle}
        title={title}
        setTitle={setTitle}
        AddNewDepartment={AddNewDepartment}
        loading={loading}
        UpdateDepartmentHandler={UpdateDepartmentHandler}
        data={data}
      />
    </>
  );
}

export default Department;
