import React from "react";
import { whiteLogo } from "../../../constant";

function AuthBackground() {
  return (
    <>
      <div className="daja-image">
        <div className="logoDv">
          <figure>
            <a href="">
              <img src={whiteLogo} alt="" />
            </a>
          </figure>
        </div>
      </div>
    </>
  );
}
export default AuthBackground;
