import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreatePassword from "../pages/AuthViews/CreatePassword";
import ForgetPassword from "../pages/AuthViews/ForgetPassword";
import Login from "../pages/AuthViews/Login";
import SignUp from "../pages/AuthViews/SignUp";
// import { Redirect } from "react-router";

function AuthRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/" element={<Layout />} /> */}
          <Route exact path="/" element={<Login />} />
          {/* <Route path="signup" element={<SignUp />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
          <Route path="createpassword" element={<CreatePassword />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AuthRoutes;

// {
//   /* <Route path="*" element={<NoMatch />} /> */
// }
// {
//   /* <Redirect to="/login" /> */
// }
