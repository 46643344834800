import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ServicesModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    pageTitle,
    name,
    setName,
    avatar,
    setAvatar,
    price,
    setPrice,
    description,
    setDescription,
    handleImageUpload,
    AddNewService,
    loading,
    editData,
    EditService,
  } = props;
  console.log(pageTitle);
  console.log(editData);
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">{pageTitle}</h2>
        <hr />
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Service Name"
        />
        <input
          type="text"
          className="mt-2 mb-2"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="price"
        />
        <input
          type="file"
          // accept="|media_type"
          onChange={handleImageUpload}
          multiple="false"
        />
        <textarea
          className="mt-2 mb-2"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => {
              pageTitle == "Edit Service"
                ? EditService(e, editData?.id)
                : AddNewService(e);
            }}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ServicesModal;
