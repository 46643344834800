import {
  ADMIN,
  SERVICE,
  STORE,
  CUSTOMER,
  SHOW,
  DRIVER,
  CHECK_CODE,
  JOB,
  ALL_JOB,
  CONTRACTOR,
  SHOW_DEPARTMENT,
  EMPLOYMENT,
  STORE_DEPARTMENT,
  SHOW_EMPLOYMENT,
  SETTING,
  SHOW_COUNTRY,
  STORE_EMPLOYMENT,
  SHOW_SERVICE,
  UPDATE_JOB,
  PAGE,
  REPORT,
  DELETE,
  UPDATE,
  DELETE_DEPART,
  DELETE_EMPLOYMENT,
  INVOICE,
  UPDATE_DEPARTMENT,
  USER,
  QUERY,
  INDEX,
  UPDATE_PAGE,
  SHOW_PAGE,
  // == END
  API,
  AUTH,
  FROGOT_PASSWORD,
  SERVICES,
  UPDATE_PROFILE,
  LOGIN,
  RESEST,
  SIGNUP,
  JOBS,
  NOTIFICATION,
  PROVIDERS,
  RATINGS,
  PAYMENT_METHODS,
  USER_SERVICES,
  CHATS,
  NOTIFICATIONS,
  CONTACTUS,
  GETREVIEW,
  UPDATE_EMPLOYMENT,
} from "../network/Endpoint";

import {
  doGet,
  doPost,
  doPatch,
  doDelete,
  doPostProfilePictureUpload,
} from "./Config";

export const postSignIn = (data) => {
  return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const PostServices = (data, token) => {
  return doPost(`${ADMIN}${SERVICE}${STORE}`, data, token);
};

export const AllServices = (currentPage) => {
  return doGet(`${SETTING}${SHOW_SERVICE}${PAGE}=${currentPage}`);
};
// ${"limit"}=${limit}
export const GetAllCustomers = (currentPage, token) => {
  return doGet(`${ADMIN}${CUSTOMER}${SHOW}${PAGE}=${currentPage}`, token);
};

export const GetAllDrivers = (currentPage, token) => {
  return doGet(`${ADMIN}${DRIVER}${SHOW}${PAGE}=${currentPage}`, token);
};

export const GetAllJobs = (currentPage, token) => {
  return doGet(`${ADMIN}${JOB}${ALL_JOB}${PAGE}=${currentPage}`, token);
};

export const UpdateJobs = (data, token) => {
  return doPost(`${ADMIN}${JOB}${UPDATE_JOB}`, data, token);
};

export const GetAllContractor = (currentPage, token) => {
  return doGet(`${ADMIN}${CONTRACTOR}${SHOW}${PAGE}=${currentPage}`, token);
};

export const PostNewContractor = (data, token) => {
  return doPost(`${ADMIN}${CONTRACTOR}${STORE}`, data, token);
};

export const GetAlldEPARTMENT = (currentPage, token) => {
  return doGet(
    `${ADMIN}${EMPLOYMENT}${SHOW_DEPARTMENT}${PAGE}=${currentPage}`,
    token
  );
};

export const PostNewDepartment = (data, token) => {
  return doPost(`${ADMIN}${EMPLOYMENT}${STORE_DEPARTMENT}`, data, token);
};

export const PostNewEmployment = (data, token) => {
  return doPost(`${ADMIN}${EMPLOYMENT}${STORE_EMPLOYMENT}`, data, token);
};

export const GetAllEmployment = (currentPage, token) => {
  return doGet(
    `${ADMIN}${EMPLOYMENT}${SHOW_EMPLOYMENT}${PAGE}=${currentPage}`,
    token
  );
};

export const GetAllCountry = (token) => {
  return doGet(`${SETTING}${SHOW_COUNTRY}`, token);
};

export const GetAllReports = (currentPage, token) => {
  return doGet(`${ADMIN}${REPORT}${SHOW}${PAGE}=${currentPage}`, token);
};

export const RemoveService = (id, token) => {
  return doGet(`${ADMIN}${SERVICE}${DELETE}/${id}`, token);
};

export const EditServiceApi = (data, token, Id) => {
  return doPost(`${ADMIN}${SERVICE}${UPDATE}/${Id}`, data, token);
};

export const RemoveDepart = (id, token) => {
  return doGet(`${ADMIN}${EMPLOYMENT}${DELETE_DEPART}/${id}`, token);
};

export const RemoveEmployment = (id, token) => {
  return doGet(`${ADMIN}${EMPLOYMENT}${DELETE_EMPLOYMENT}/${id}`, token);
};

export const RemoveContractor = (id, token) => {
  return doGet(`${ADMIN}${CONTRACTOR}${DELETE}/${id}`, token);
};

export const EditContractor = (data, token, id) => {
  return doPost(`${ADMIN}${CONTRACTOR}${UPDATE}/${id}`, data, token);
};

export const GetAllInvoice = (currentPage, token) => {
  return doGet(`${ADMIN}${JOB}${INVOICE}${PAGE}=${currentPage}`, token);
};

export const UpdateDepartment = (data, token, id) => {
  return doPost(`${ADMIN}${EMPLOYMENT}${UPDATE_DEPARTMENT}/${id}`, data, token);
};

export const updateEmploymentApi = (data, token, id) => {
  return doPost(`${ADMIN}${EMPLOYMENT}${UPDATE_EMPLOYMENT}/${id}`, data, token);
};

export const EditProfileApi = (data, token) => {
  return doPost(`${USER}${UPDATE}`, data, token);
};

export const GetAllQuote = (currentPage, token) => {
  return doGet(`${ADMIN}${QUERY}${INDEX}${PAGE}=${currentPage}`, token);
};

export const UpdateTermsCondition = (data, id) => {
  return doPost(`${SETTING}${UPDATE_PAGE}/${id}`, data);
};

export const GetTermCondition = () => {
  return doGet(`${SETTING}${SHOW_PAGE}`);
};

// == END ==

// export const postSignIn = (data) => {
//   return doPost(`${API}${AUTH}${LOGIN}`, { ...data });
// };

export const forgotPasswordOtp = (data) => {
  return doPost(`${AUTH}${CHECK_CODE}`, data);
};

export const postSignUp = (data) => {
  return doPost(`${API}${AUTH}${SIGNUP}`, data);
};

export const forgotPasswordEmailApi = (data) => {
  return doPost(`${API}${AUTH}${RESEST}`, data);
};

export const forgotPasswordApi = (data) => {
  return doPost(`${API}${AUTH}${FROGOT_PASSWORD}`, data);
};

export const setNewPasswordApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};
export const logoutApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};

export const GetAllServices = (token) => {
  return doGet(`${API}${SERVICES}`, token);
};

export const GetAllUserServices = (token) => {
  return doGet(`${API}${USER_SERVICES}`, token);
};

export const PostAddUserServices = (data, token) => {
  return doPost(`${API}${USER_SERVICES}`, data, token);
};

export const DeleteUserServicesApi = (id, token) => {
  return doDelete(`${API}${USER_SERVICES}/${id}`, token);
};

export const GetAllJOBS = (token) => {
  return doGet(`${API}${JOBS}`, token);
};

export const POSTJOBS = (data, token) => {
  return doPost(`${API}${JOBS}`, data, token);
};

export const UpdateJOB = (id, data, token) => {
  return doPatch(`${API}${JOBS}/${id}`, data, token);
};

export const GetAllNotifications = (token) => {
  return doGet(`${API}${NOTIFICATION}`, token);
};

export const GetAllProviders = (token, id) => {
  return doGet(`${API}${PROVIDERS}/${id}`, token);
};

export const UpdateProfile = (data, token) => {
  return doPostProfilePictureUpload(
    `${API}${AUTH}${UPDATE_PROFILE}`,
    data,
    token
  );
};

export const BookingFinishedRating = (data, token) => {
  return doPost(`${API}${RATINGS}`, data, token);
};

export const GetAllPaymentMethods = (token) => {
  return doGet(`${API}${PAYMENT_METHODS}`, token);
};

export const AddPaymentMethods = (data, token) => {
  return doPost(`${API}${PAYMENT_METHODS}`, data, token);
};

export const DeletePaymentMethodsApi = (id, token) => {
  return doDelete(`${API}${PAYMENT_METHODS}/${id}`, token);
};

export const GetChats = (id, token) => {
  return doGet(`${API}${CHATS}/${id}`, token);
};

// export const PostChats = (data,token,) => {
//   return doPost(`${API}${CHATS}`, data,token);
// };

export const PostChats = (data, token) => {
  return doPostProfilePictureUpload(`${API}${CHATS}`, data, token);
};

export const GetAllChats = (token) => {
  return doGet(`${API}${CHATS}`, token);
};

export const PostContactus = (data, token) => {
  return doPost(`${API}${CONTACTUS}`, data, token);
};

export const GetAllReview = (token) => {
  return doGet(`${API}${GETREVIEW}`, token);
};
