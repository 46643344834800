import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { GetAllJobs } from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Jobs() {
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [jobsData, setJobsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllJobs(currentPage, Token)
      .then((res) => {
        console.log(res);
        setJobsData(res?.data?.response?.data?.data);
        // dispatch(AllServices(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);
  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Jobs </p>
        <div className="serviceCard">
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Code</th>
                  <th scope="col">Destination Person</th>
                  <th scope="col">Origin Person</th>
                  <th scope="col">Tracking </th>
                  <th scope="col">Driver</th>
                  <th scope="col">Status</th>
                  <th scope="col"> View </th>
                </tr>
              </thead>
              <tbody>
                {jobsData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.code}</td>
                      <td>{item?.contact_name_destination}</td>
                      <td>{item?.contact_name_origin}</td>
                      <td>{item?.tracking_code}</td>
                      <td>
                        <>
                          {item?.driver ? (
                            <>
                              {item?.driver?.first_name}{" "}
                              {item?.driver?.last_name}
                            </>
                          ) : (
                            "--"
                          )}
                        </>
                      </td>
                      <td>
                        {/* {item?.status_id == "5"
                          ? "Pending"
                          : item?.status_id == "6"
                          ? "Assigned"
                          : item?.status == "Accepted"
                          ? "Accepted"
                          : "Please add Status"} */}
                        {item?.status}
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "unset" }}
                            onClick={() =>
                              Navigate(`/adminpanel/jobdetails/${item?.id}`, {
                                state: item,
                              })
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
