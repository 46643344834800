// Define api end points here

export const ADMIN = "/admin";
export const SERVICE = "/service";
export const STORE = "/store";
export const SHOW = "/show";
export const CUSTOMER = "/customer";
export const DRIVER = "/driver";
export const CHECK_CODE = "/checkCode";

export const JOB = "/job";
export const ALL_JOB = "/allJob";
export const CONTRACTOR = "/contractor";

export const EMPLOYMENT = "/employment";
export const SHOW_DEPARTMENT = "/showDepartment";

export const STORE_DEPARTMENT = "/storeDepartment";
export const STORE_EMPLOYMENT = "/storeEmployment";

export const SHOW_EMPLOYMENT = "/showEmployment";

export const SETTING = "/setting";
export const SHOW_COUNTRY = "/showCountry";

export const SHOW_SERVICE = "/showService";
export const UPDATE_JOB = "/updateJob";
export const PAGE = "?page";

export const REPORT = "/report";

export const DELETE = "/delete";

export const UPDATE = "/update";

export const DELETE_DEPART = "/deleteDepart";
export const DELETE_EMPLOYMENT = "/deleteEmployment";

export const INVOICE = "/invoice";
export const UPDATE_DEPARTMENT = "/updateDepartment";

export const UPDATE_EMPLOYMENT = "/updateEmployment";

export const USER = "/user";

export const QUERY = "/query";
export const INDEX = "/index";

export const UPDATE_PAGE = "/updatePage";

export const SHOW_PAGE = "/showPage";

// == End

export const API = "/api";
export const AUTH = "/auth";
export const SIGNUP = "/register";
export const LOGIN = "/login";
export const FROGOT_PASSWORD = "/forgot";

export const SERVICES = "/services";
export const UPDATE_PROFILE = "/update-profile";
export const RESEST = "/reset-password";

export const JOBS = "/jobs";
export const NOTIFICATION = "/notifications";
export const PROVIDERS = "/providers";
export const RATINGS = "/ratings";
export const PAYMENT_METHODS = "/payment-methods";

export const USER_SERVICES = "/user-services";

export const CHATS = "/chats";

export const CONTACTUS = "/storeContactUs";

export const GETREVIEW = "/getReview";
