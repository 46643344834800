import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  EditContractor,
  GetAllContractor,
  GetAlldEPARTMENT,
  PostNewContractor,
  RemoveContractor,
} from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ContractorModal from "../../../components/DashboardComp/Modal/ContractorModal";
import { AllDepartments } from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function Contractor() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const DepartmentData = useSelector((state) => state.AuthReducer.department);
  const CountryData = useSelector((state) => state.AuthReducer.country);

  console.log(DepartmentData);
  console.log(CountryData);

  const [allContractorData, setAllContractorData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [pageTitle, setPageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [department_id, setDepartment_id] = useState("");
  const [location_id, setLocation_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllContractor(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllContractorData(res?.data?.response?.data?.data);
        // dispatch(AllServices(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  // == get Department api ==
  useEffect(() => {
    GetAlldEPARTMENT(Token)
      .then((res) => {
        dispatch(AllDepartments(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);

  //  == Post New Contactor Api ==
  const AddNewContract = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!department_id || !location_id || !title || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (description.length < 20) {
      toast.error("Description must be at least 20 characters.");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("department_id", department_id);
    data.append("location_id", location_id);
    data.append("title", title);
    data.append("description", description);
    PostNewContractor(data, Token)
      .then((res) => {
        console.log(res);
        allContractorData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setIsOpenModal(false);
        setDepartment_id("");
        setLocation_id("");
        setTitle(" ");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setDepartment_id("");
        setLocation_id("");
        setTitle(" ");
        setDescription("");
      });
  };

  // == Remove Service ==
  const DeleteContractHandler = (id, index) => {
    RemoveContractor(id, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        allContractorData?.splice(index, 1);
        setAllContractorData(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  == Edit contractor Api ==
  const EditContractorHandler = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if (!department_id || !location_id || !title || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (description.length < 20) {
      toast.error("Description must be at least 20 characters.");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("department_id", department_id);
    data.append("location_id", location_id);
    data.append("title", title);
    data.append("description", description);
    EditContractor(data, Token, id)
      .then((res) => {
        console.log(res);
        allContractorData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setDepartment_id("");
        setLocation_id("");
        setTitle(" ");
        setDescription("");
        setEditData("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setDepartment_id("");
        setLocation_id("");
        setTitle(" ");
        setDescription("");
        setEditData("");
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Contractor </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Contractor");
              setDepartment_id("");
              setLocation_id("");
              setTitle("");
              setDescription("");
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Contactor
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Details</th>
                  <th scope="col">Country</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                  <th scope="col"> View Details</th>
                </tr>
              </thead>
              <tbody>
                {allContractorData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.title}</td>
                      <td>
                        <div className="text-newWrap">{item?.description}</div>
                      </td>
                      <td>
                        {item?.country?.name ? item?.country?.name : "--"}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Contractor");
                            setDepartment_id(item?.department_id);
                            setLocation_id(item?.location_id);
                            setTitle(item?.title);
                            setDescription(item?.description);
                            setEditData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteContractHandler(item?.id, index)}
                        />
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "unset" }}
                            onClick={() =>
                              Navigate(
                                `/adminpanel/contractordetails/${item?.id}`,
                                {
                                  state: item,
                                }
                              )
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <ContractorModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        pageTitle={pageTitle}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        department_id={department_id}
        setDepartment_id={setDepartment_id}
        location_id={location_id}
        setLocation_id={setLocation_id}
        AddNewContract={AddNewContract}
        loading={loading}
        CountryData={CountryData}
        DepartmentData={DepartmentData}
        EditContractorHandler={EditContractorHandler}
        editData={editData}
      />
    </>
  );
}

export default Contractor;
