import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHome,
  faShippingFast,
  faCommentDollar,
  faMoneyCheck,
  faSignOutAlt,
  faQuestionCircle,
  faFlag,
  faFileContract,
  faPortrait,
  faFileSignature,
  faUserShield,
  faAddressBook,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../../redux/actions/AuthAction";

function SideBar() {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [home, setHome] = useState(false);
  const [order, setOrder] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [quote, setQuote] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [employment, setEmployment] = useState(false);
  const [contract, setContract] = useState(false);
  const [help, setHelp] = useState(false);
  const [contractor, setContractor] = useState(false);
  const [logout, setLogout] = useState(false);
  const [department, setDepartment] = useState(false);
  const [newEmployment, setNewEmployment] = useState(false);
  const [report, setReport] = useState(false);
  const [getquote, setGetquote] = useState(false);
  const [term, setTerm] = useState(false);

  const [loading, setLoading] = useState(false);

  const activeStateHandler = (type) => {
    if (type === "home") {
      setHome(true);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "order") {
      setHome(false);
      setOrder(true);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "tracking") {
      setHome(false);
      setOrder(false);
      setTracking(true);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "quote") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(true);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "invoice") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(true);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "employment") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(true);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "contract") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(true);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "help") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(true);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "department") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(true);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "newEmployment") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(true);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "getquote") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(true);
      setReport(false);
      setTerm(false);
      setLogout(false);
    } else if (type === "term") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(true);
      setLogout(false);
    } else {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setHelp(false);
      setDepartment(false);
      setNewEmployment(false);
      setGetquote(false);
      setReport(false);
      setTerm(false);
      setLogout(true);
    }
  };

  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(Logout());
      window.location.href = "/";
    }, 200);
  };
  return (
    <>
      <ul className="sidebar-nav">
        <li
          className={type === "home" ? "active" : null}
          onClick={() => activeStateHandler(setType("home"))}
        >
          <Link to="/adminpanel/services">
            <FontAwesomeIcon
              icon={faHome}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Services
          </Link>
        </li>
        <li
          className={type === "order" ? "active" : null}
          onClick={() => activeStateHandler(setType("order"))}
        >
          <Link to="/adminpanel/customers">
            <FontAwesomeIcon
              icon={faAddressBook}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Customers
          </Link>
        </li>
        <li
          className={type === "tracking" ? "active" : null}
          onClick={() => activeStateHandler(setType("tracking"))}
        >
          <Link to="/adminpanel/Drivers">
            <FontAwesomeIcon
              icon={faShippingFast}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Drivers
          </Link>
        </li>
        <li
          className={type === "quote" ? "active" : null}
          onClick={() => activeStateHandler(setType("quote"))}
        >
          <Link to="/adminpanel/jobs">
            <FontAwesomeIcon
              icon={faBriefcase}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Jobs
          </Link>
        </li>
        <li
          className={type === "contractor" ? "active" : null}
          onClick={() => activeStateHandler(setType("contractor"))}
        >
          <Link to="/adminpanel/contractor">
            <FontAwesomeIcon
              icon={faFileSignature}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Contractor
          </Link>
        </li>
        <li
          className={type === "invoice" ? "active" : null}
          onClick={() => activeStateHandler(setType("invoice"))}
        >
          <Link to="/adminpanel/payments">
            <FontAwesomeIcon
              icon={faMoneyCheck}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Payments
          </Link>
        </li>
        {/* <li
          className={type === "admin" ? "active" : null}
          onClick={() => activeStateHandler(setType("admin"))}
        >
          <Link to="/adminpanel/addAdmin">
            <FontAwesomeIcon
              icon={faUserShield}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Add Admin
          </Link>
        </li> */}
        <li
          className={type === "department" ? "active" : null}
          onClick={() => activeStateHandler(setType("department"))}
        >
          <Link to="/adminpanel/department">
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Departments
          </Link>
        </li>
        <li
          className={type === "newEmployment" ? "active" : null}
          onClick={() => activeStateHandler(setType("newEmployment"))}
        >
          <Link to="/adminpanel/employment">
            <FontAwesomeIcon
              icon={faPortrait}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Employment
          </Link>
        </li>

        <li
          className={type === "getquote" ? "active" : null}
          onClick={() => activeStateHandler(setType("getquote"))}
        >
          <Link to="/adminpanel/getquote">
            <FontAwesomeIcon
              icon={faFlag}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            All Quote
          </Link>
        </li>
        <li
          className={type === "report" ? "active" : null}
          onClick={() => activeStateHandler(setType("report"))}
        >
          <Link to="/adminpanel/reportissue">
            <FontAwesomeIcon
              icon={faFlag}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Report Issue
          </Link>
        </li>
        <li
          className={type === "term" ? "active" : null}
          onClick={() => activeStateHandler(setType("term"))}
        >
          <Link to="/adminpanel/termCondition">
            <FontAwesomeIcon
              icon={faFlag}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Term&Condition
          </Link>
        </li>

        <li
          className={type === "logout" ? "active" : null}
          onClick={() => activeStateHandler(setType("logout"))}
        >
          <a href="" onClick={(e) => LogoutHandler(e)}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Logout
          </a>
        </li>
      </ul>
    </>
  );
}

export default SideBar;
