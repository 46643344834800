import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { GetAllDrivers, UpdateJobs } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import AssignJobModal from "../../../components/DashboardComp/Modal/AssignJobModal";
import { AllDrivers } from "../../../redux/actions/AuthAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function JobDetails() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Location = useLocation();
  const Token = useSelector((state) => state.AuthReducer.token);
  const Drivers = useSelector((state) => state.AuthReducer.drivers);
  const [driverData, setDriverData] = useState(Drivers);
  console.log(Location);
  const JobData = Location?.state;

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [driver_id, setDriver_id] = useState("");

  //   UpdateJobs
  const UpdateJobHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("status", "6");
    data.append("driver_id", driver_id);
    data.append("job_id", JobData?.id);

    UpdateJobs(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        Navigate("/adminpanel/jobs");
        setLoading(false);
        setIsOpenModal(false);
        setDriver_id("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setDriver_id("");
      });
  };

  //  == Get All Driver Api ==
  useEffect(() => {
    GetAllDrivers(Token)
      .then((res) => {
        dispatch(AllDrivers(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);
  return (
    <>
      <div className="driver-Container">
        <p className="dashboard-title">Job Details </p>
        <div className="serviceCard">
          {/* <div className="flexBox-style">
            <div className="profilePicture-container">
              <img
                src={`${ramdomImage(JobData?.first_name)}`}
                className="image-fluid image-width"
                alt=""
              />
            </div>
          </div>
          <p className="driver-details">
            {JobData?.delivery_f_name}
            {""} {JobData?.delivery_l_name}
          </p> */}
          <div className="row">
            <div className="col-md-6">
              <p className="driver-details">
                code: {""}
                {JobData?.code}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Delivery Address: {""}
                {JobData?.delivery_address}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Delivery City: {""}
                {JobData?.delivery_city}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Contact Name Origin: {""} {JobData?.contact_name_origin}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Contact Name Destination: {""}
                {JobData?.contact_name_destination}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                item: {""}
                {JobData?.item}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Tracking code: {""}
                {JobData?.tracking_code}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Driver Name: {""}
                {JobData?.driver ? (
                  <>
                    {JobData?.driver?.first_name} {JobData?.driver?.last_name}
                  </>
                ) : (
                  "Waiting for Assign"
                )}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Updated_at: {""}
                {moment(JobData?.updated_at).format("MMMM Do YYYY, h:mm")}
              </p>
            </div>

            {JobData?.status_id == "5" || JobData?.status == "Canceled" ? (
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "unset" }}
                  onClick={() => setIsOpenModal(true)}
                >
                  {JobData?.status == "Canceled" ? "ResignJob" : "Assign Job"}
                </button>
              </div>
            ) : (
              <p className="mt-2">{JobData?.status}</p>
            )}
          </div>
        </div>
      </div>
      <AssignJobModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        driverData={driverData}
        loading={loading}
        driver_id={driver_id}
        setDriver_id={setDriver_id}
        UpdateJobHandler={UpdateJobHandler}
      />
    </>
  );
}

export default JobDetails;
