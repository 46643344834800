import React from "react";
import NotificationCard from "../../../components/DashboardComp/Notification/NotificationCard";

function Notification() {
  return (
    <>
      <div className="notification-Container">
        <p className="dashboard-title">Notification</p>
        <div className="serviceCard">
          <div className="notificationCard">
            <NotificationCard />
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
