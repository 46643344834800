import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ContractorModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    pageTitle,

    title,
    setTitle,
    description,
    setDescription,
    department_id,
    setDepartment_id,
    location_id,
    setLocation_id,
    AddNewContract,
    loading,
    DepartmentData,
    CountryData,
    EditContractorHandler,
    editData,
  } = props;

  console.log(pageTitle);
  console.log("edit", editData);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">{pageTitle}</h2>
        <hr />
        <select
          className="custom-select my-1 mr-sm-2 form-control mb-3"
          id="inlineFormCustomSelectPref"
          onChange={(e) => {
            setDepartment_id(e.target.value);
          }}
        >
          <option selected>select Department</option>
          {DepartmentData?.map((item, index) => {
            return (
              <option value={item?.id} key={item?.id}>
                {item?.title}
              </option>
            );
          })}
        </select>
        <select
          className="custom-select my-1 mr-sm-2 form-control mb-3"
          id="inlineFormCustomSelectPref"
          onChange={(e) => {
            setLocation_id(e.target.value);
          }}
        >
          <option selected>select Country</option>
          {CountryData?.map((item, index) => {
            return (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            );
          })}
        </select>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Contractor Title"
          className="mb-3"
        />

        <textarea
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Contractor Description"
          className="mb-3"
        />

        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => {
              pageTitle == "Edit Contractor"
                ? EditContractorHandler(e, editData?.id)
                : AddNewContract(e);
            }}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ContractorModal;
