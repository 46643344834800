import React from "react";
import { useLocation } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";

function ContractorDetails() {
  const Location = useLocation();
  console.log(Location);
  const CustomerData = Location?.state;
  return (
    <>
      <div className="driver-Container">
        <p className="dashboard-title">Contractor Details </p>
        <div className="serviceCard">
          <p className="driver-details">{CustomerData?.title}</p>
          <div className="row">
            <div className="col-md-12">
              <p className="driver-details">{CustomerData?.description}</p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Department: {""}
                {CustomerData?.department?.title}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Country: {""}
                {CustomerData?.country?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractorDetails;
