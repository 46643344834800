import React, { useState, useEffect } from "react";
import { BrowserRouter, Link, NavLink, Routes, Outlet } from "react-router-dom";
import $ from "jquery";
import "@fortawesome/fontawesome-svg-core/styles.css";
// import "../../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../../../components/DashboardComp/Sidebar/SideBar";
import { dashLogo } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBullhorn,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import DashboardRoutes from "../../../routes/DashboardRoutes";
import { ramdomImage } from "../../../constant/ConstantFunction";
import "../../../assets/css/DashboardLayout.css";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../../network/ApiUrl";

function DashboardLayout() {
  const UserData = useSelector((state) => state.AuthReducer.users);
  const [type, setType] = useState("");
  const [chatActive, setChatActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);

  const $a = document.querySelector("#sidebar-toggle");
  const toggleNavBar = (e) => {
    e.preventDefault();
    document.querySelector("#wrapper").classList.toggle("toggled");
  };

  const handleActive = (type) => {
    if (type === "chat") {
      setChatActive(true);
      setNotificationActive(false);
    } else if (type === "notification") {
      setNotificationActive(true);
      setChatActive(false);
    }
  };

  return (
    <>
      <BrowserRouter>
        <div id="wrapper">
          <div id="navbar-wrapper">
            <nav className="navbar navbar-inverse">
              <div className="container-fluid">
                <div className="navbar-header">
                  <a className="navbar-brand nav_logo">
                    <img src={dashLogo} className="image-fluid" />
                  </a>
                </div>
                {/* <Link to="/">Visit website</Link> */}
                <div className="navbar-header">
                  <a
                    className="navbar-brand distance-width"
                    id="sidebar-toggle"
                    onClick={(e) => toggleNavBar(e)}
                  >
                    <i className="fa fa-bars"></i>
                  </a>
                </div>

                <div className="navbar-header">
                  <div className="custom-topBar">
                    <ul>
                      {/* <li
                        className={type === "chat" ? "Active" : null}
                        onClick={() => {
                          handleActive(setType("chat"));
                        }}
                      >
                        <Link to="/adminpanel/chat">
                          <FontAwesomeIcon
                            icon={faCommentAlt}
                            className={
                              type === "chat" ? "Active" : "notification-icon"
                            }
                          />
                        </Link>
                      </li> */}
                      {/* <li
                        className={type === "notification" ? "Active" : null}
                        onClick={() => {
                          handleActive(setType("notification"));
                        }}
                      >
                        <Link to="/adminpanel/notification">
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            // className="notification-icon"
                            className={
                              type === "notification"
                                ? "Active"
                                : "notification-icon"
                            }
                          />
                        </Link>
                      </li> */}
                      {/* <li
                        onClick={() => {
                          handleActive(setType("setting"));
                        }}
                      >
                        <Link to="">
                          <FontAwesomeIcon
                            icon={faCog}
                            // className="notification-icon"
                            className={
                              type === "setting"
                                ? "Active"
                                : "notification-icon"
                            }
                          />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="navbar-header">
                  <div className="profile-container">
                    <Link to="adminpanel/profile">
                      <div className="profile">
                        {/* <img
                          src={`${ramdomImage("Ahsan Mukhtar")}`}
                          className="image-fluid image-width"
                          alt=""
                        /> */}
                        <img
                          src={`${ImageUrl}/${UserData?.profile_picture}`}
                          className="image-fluid image-width"
                          alt=""
                        />
                      </div>
                      <div className="ml-2">
                        <p className="navbar-profile-name">
                          {UserData?.first_name}
                        </p>
                        <p className="navbar-profile-role">Admin</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <aside id="sidebar-wrapper">
            <SideBar />
          </aside>

          <section id="content-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <DashboardRoutes />
              </div>
            </div>
          </section>
        </div>
      </BrowserRouter>
    </>
  );
}

export default DashboardLayout;
