import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function DepartmentModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    pageTitle,
    title,
    setTitle,
    AddNewDepartment,
    loading,
    UpdateDepartmentHandler,
    data,
  } = props;
  console.log(pageTitle);
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">{pageTitle}</h2>
        <hr />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Department Title"
        />

        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => {
              pageTitle == "Edit Department"
                ? UpdateDepartmentHandler(e, data?.id)
                : AddNewDepartment(e);
            }}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DepartmentModal;
