import React from "react";
import { ramdomImage } from "../../../constant/ConstantFunction";

function NotificationCard() {
  return (
    <>
      <div className="row">
        <div className="col-md-4 col-4">
          <div className="notification-profile">
            <img
              src={`${ramdomImage("Ahsan Mukhtar")}`}
              className="image-fluid image-width"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-4 col-8">
          <p className="notification-title">Lorem ipsum dolor sit</p>
          <p className="date">12-12-21 08:30 pm</p>
        </div>
        <div className="col-md-4 col-12">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "unset" }}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationCard;
