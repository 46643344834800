import React from "react";
import {
  Route,
  Navigate,
  Outlet,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import ServiceType from "../pages/Dashboard/ServiceType/ServiceType";
import ProtectedRoutes from "./ProtectedRoutes";
import Chat from "../pages/Dashboard/Chat/Chat";
import Profile from "../pages/Dashboard/Profile/Profile";
import EditProfile from "../pages/Dashboard/Profile/EditProfile";
import Notification from "../pages/Dashboard/Notification/Notification";
import Customers from "../pages/Dashboard/Customers/Customers";
import Drivers from "../pages/Dashboard/Drivers/Drivers";
import Jobs from "../pages/Dashboard/Jobs/Jobs";
import Payments from "../pages/Dashboard/Payments/Payments";
import DriverDetails from "../pages/Dashboard/Drivers/DriverDetails";
import CreateAdmin from "../pages/Dashboard/CreateAdmin/CreateAdmin";
import Contractor from "../pages/Dashboard/Contractor/Contractor";
import Department from "../pages/Dashboard/Employment/Department";
import Employment from "../pages/Dashboard/Employment/Employment";
import JobDetails from "../pages/Dashboard/Jobs/JobDetails";
import CustomerDetails from "../pages/Dashboard/Customers/CustomerDetails";
import ContractorDetails from "../pages/Dashboard/Contractor/ContractorDetails";
import EmploymentDetails from "../pages/Dashboard/Employment/EmploymentDetails";
import ReportIssue from "../pages/Dashboard/Report/ReportIssue";
import GetQuote from "../pages/Dashboard/GetQuote/GetQuote";
import TermCondition from "../pages/TermCondition/TermCondition";

function DashboardRoutes() {
  return (
    <>
      <Routes>
        <Route path="adminpanel" element={<ProtectedRoutes />}>
          <Route path="services" element={<ServiceType />} />
          <Route path="customers" element={<Customers />} />
          <Route path="drivers" element={<Drivers />} />

          <Route path="jobs" element={<Jobs />} />
          <Route path="payments" element={<Payments />} />

          <Route path="chat" element={<Chat />} />
          <Route path="profile" element={<Profile />} />
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="notification" element={<Notification />} />
          <Route path="driverdetails/:Id" element={<DriverDetails />} />

          <Route path="addAdmin" element={<CreateAdmin />} />
          <Route path="contractor" element={<Contractor />} />

          <Route path="department" element={<Department />} />

          <Route path="employment" element={<Employment />} />

          <Route path="jobdetails/:Id" element={<JobDetails />} />

          <Route path="customerdetails/:Id" element={<CustomerDetails />} />

          <Route path="contractordetails/:Id" element={<ContractorDetails />} />

          <Route path="employmentdetails/:Id" element={<EmploymentDetails />} />

          <Route path="reportissue" element={<ReportIssue />} />
          <Route path="getquote" element={<GetQuote />} />
          <Route path="termCondition" element={<TermCondition />} />

          <Route path="*" element={<Navigate to="/adminpanel/services" />} />
        </Route>
      </Routes>
    </>
  );
}

export default DashboardRoutes;
