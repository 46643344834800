import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function AssignJobModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    loading,
    driver_id,
    setDriver_id,
    UpdateJobHandler,
    driverData,
  } = props;

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">Assign Job</h2>
        <hr />
        <select
          className="custom-select my-1 mr-sm-2 form-control mb-3"
          id="inlineFormCustomSelectPref"
          onChange={(e) => {
            setDriver_id(e.target.value);
          }}
        >
          <option selected>select Driver</option>
          {driverData?.map((item, index) => {
            return (
              <option value={item?.id} key={item?.id}>
                {item?.first_name}
                {""} {item?.last_name}
              </option>
            );
          })}
        </select>

        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => UpdateJobHandler(e)}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default AssignJobModal;
